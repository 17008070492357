<template>
  <section class="page-content-container">
    <div v-html="pageData.post_content"></div>
  </section>
</template>

<script>
export default {
  props: {
    pageData: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content-container {
  @include section__container;
  padding: 60px 7%;
}
</style>

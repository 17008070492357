<template>
  <div>
    <section
      id="hiw__intro--section"
      style="background-image: url(https://www.aquabedwarmer.com/dist/media/2016/04/background-how-it-works-banner.jpg);"
    >
      <div class="container">
        <div class="row">
          <div class="seven columns no-show-tablet">
            <h1>No Wires, Just Water</h1>
            <p>The Aqua Bed Warmer circulates water through a soft mattress pad that goes directly on top of your mattress underneath a fitted sheet.</p>
          </div>

          <div class="twelve columns no-show-desktop">
            <h1>No Wires, Just Water</h1>
          </div>
        </div>
      </div>
    </section>

    <section id="hiw__heater--section">
      <div class="container">
        <div class="row flex-row">
          <div class="six columns hiw__heater--image">
            <img
              src="https://i2.wp.com/www.aquabedwarmer.com/dist/media/2016/04/aquabed-console-vector.jpg?zoom=2.5&amp;fit=534%2C920&amp;ssl=1"
              alt="Aqua bed warmer heater unit"
              width="270"
              height="465"
              src-orig="https://i2.wp.com/www.aquabedwarmer.com/dist/media/2016/04/aquabed-console-vector.jpg?fit=534%2C920&amp;ssl=1"
              scale="2.5"
            >
          </div>

          <div class="six columns hiw__heater--content-container">
            <div class="hiw__heater--content">
              <h2>The Heater</h2>
              <p>Everything starts and ends with our water heater, which safely and efficiently heats the water inside to your specified temperature.</p>
              <ul>
                <li>
                  <span>
                    <img
                      src="https://i0.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-temperature-and-runtime-control.png?zoom=2.5&amp;fit=90%2C82&amp;ssl=1"
                      alt="Temperature and runtime control icon"
                      width="40"
                      height="36"
                      src-orig="https://i0.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-temperature-and-runtime-control.png?fit=90%2C82&amp;ssl=1"
                      scale="2.5"
                    >
                  </span> Temperature &amp; Runtime Control
                </li>
                <li>
                  <span>
                    <img
                      src="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-whisper-quiet-operation.png?zoom=2.5&amp;fit=90%2C82&amp;ssl=1"
                      alt="Quiet icon"
                      width="40"
                      height="36"
                      src-orig="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-whisper-quiet-operation.png?fit=90%2C82&amp;ssl=1"
                      scale="2.5"
                    >
                  </span> Whisper Quiet Operation
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hiw__pad--section">
      <div class="container">
        <div class="row reorder-elements-container flex-row">
          <div class="six columns reorder-elements-two hiw__pad--content-container">
            <div class="hiw__pad--content">
              <h2>The Pad</h2>
              <p>The water heater's built-in motor pumps the warm water through the mattress pad's intricate tubing system, distributing heat evenly throughout the bed.</p>
              <ul>
                <li>
                  <span>
                    <img
                      src="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-100-cotton-mattress-pad.png?zoom=2.5&amp;fit=90%2C82&amp;ssl=1"
                      alt="Cotton icon"
                      width="40"
                      height="36"
                      src-orig="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-100-cotton-mattress-pad.png?fit=90%2C82&amp;ssl=1"
                      scale="2.5"
                    >
                  </span> 100% Cotton Mattress Pad
                </li>
                <li>
                  <span>
                    <img
                      src="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-plush-top-for-comfort.png?zoom=2.5&amp;fit=90%2C82&amp;ssl=1"
                      alt="icon plush top for comfort"
                      width="40"
                      height="36"
                      src-orig="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/icon-plush-top-for-comfort.png?fit=90%2C82&amp;ssl=1"
                      scale="2.5"
                    >
                  </span> Plush Top for Comfort
                </li>
              </ul>
            </div>
          </div>

          <div class="six columns hiw__pad--image reorder-elements-one">
            <img
              src="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/aquabed-pad-vector.jpg?zoom=2.5&amp;fit=846%2C934&amp;ssl=1"
              alt="Aqua bed warmer heater pad"
              width="345"
              height="381"
              src-orig="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/aquabed-pad-vector.jpg?fit=846%2C934&amp;ssl=1"
              scale="2.5"
            >
          </div>
        </div>
      </div>
    </section>

    <section id="hiw__experience--section">
      <div class="container">
        <div class="row flex-row">
          <div class="hidden hiw__experience--images">
            <span class="hiw__experience--single-image">
              <img
                src="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/diagram-single.jpg?zoom=2.5&amp;fit=1148%2C1063&amp;ssl=1"
                alt="Aqua bed warmer single unit"
                width="1148"
                height="1063"
                src-orig="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/diagram-single.jpg?fit=1148%2C1063&amp;ssl=1"
                scale="2.5"
              >
            </span>
            <span class="hiw__experience--dual-image">
              <img
                src="https://i2.wp.com/www.aquabedwarmer.com/dist/media/2016/04/diagram-dual.jpg?zoom=2.5&amp;fit=1148%2C1063&amp;ssl=1"
                alt="Aqua bed warmer dual unit"
                width="1148"
                height="1063"
                src-orig="https://i2.wp.com/www.aquabedwarmer.com/dist/media/2016/04/diagram-dual.jpg?fit=1148%2C1063&amp;ssl=1"
                scale="2.5"
              >
            </span>
          </div>

          <div class="six columns hiw__experience--image">
            <img
              src="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/diagram-single.jpg?zoom=2.5&amp;fit=1148%2C1063&amp;ssl=1"
              alt="Aqua bed warmer dual unit"
              width="390"
              height="361"
              src-orig="https://i1.wp.com/www.aquabedwarmer.com/dist/media/2016/04/diagram-single.jpg?fit=1148%2C1063&amp;ssl=1"
              scale="2.5"
            >
          </div>

          <div class="six columns hiw__experience--content">
            <h2>The Experience</h2>
            <p>As warm water circulates through the mattress pad, a magical thing happens: your bed, your blankets, and your pillows will all be warmed to your perfect sleeping temperature.
              <br>
              <br>Select either a single or dual heating experience.
            </p>
            <div class="hiw__experience--cta">
              <span class="hiw__experience--single-button button__secondary">Single System</span>
              <span class="hiw__experience--dual-button button__secondary">Dual System</span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hiw__faqs--section">
      <div class="container">
        <div class="row">
          <div class="twelve columns">
            <h3>FAQs</h3>
          </div>
        </div>

        <div class="row">
          <div class="five columns">
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Is the Aqua Bed Warmer safe?
                  <span
                    class="hiw__faqs--answer"
                  >Absolutely! The Aqua Bed Warmer only uses heated water to heat your bed at night. It is just as safe as sleeping with a hot water bottle at night, except it will stay warm as long as you want it to. Unlike traditional electric blankets, there are no health or fire risks associated with the Aqua Bed Warmer.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  How often do I have to change out the water in my Aqua Bed Warmer Water Heater?
                  <span
                    class="hiw__faqs--answer"
                  >We recommend that you switch out the water in your AquaHeater once every 3-4 months. Directions on how to switch out your water can be found in your Aqua Bed Warmer user manual.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Are Electric Blankets safe?
                  <span
                    class="hiw__faqs--answer"
                  >Electric blankets can be dangerous for a number of reasons. First, if damaged, electric blankets can easily overheat and present both burn and fire risks to individuals. If you are pregnant or elderly, electric blankets are an unnecessary risk in the bedroom.
                    <br>
                    <br>Electric blankets also emit electromagnetic fields, or EMFs. While the effects of EMFs is subject to debate and discussion, the US National Institute for Occupational Safety and Health has issued cautionary advisories regarding excessive exposure. The Aqua Bed Warmer is the safest alternative to electric blankets and has no electromagnetic fields, or no EMFs.
                  </span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Does the Aqua Bed Warmer fit my bed?
                  <span
                    class="hiw__faqs--answer"
                  >The Aqua Bed Warmer is available in Twin, Full, Queen, and King sizes. The mattress pad is sized based on industry standards for bed sizes and may be an inch short or long depending on your mattress manufacturer.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Where should I place my Aqua Bed Warmer Water Heater?
                  <span
                    class="hiw__faqs--answer"
                  >The Water Heater can be placed anywhere it is within reach of the AquaPad’s connecting cord. However, we strongly recommend keeping the AquaHeater less than 2 feet above or below the AquaPad to ensure consistent water circulation. Deep/soft carpets can also be troublesome for the AquaHeater if the tilt sensor (located on the bottom of the heater) cannot detect the floor.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Can I use the Aqua Bed Warmer during pregnancy?
                  <span
                    class="hiw__faqs--answer"
                  >The Aqua Bed Warmer only uses heated, circulating water to heat your bed, so it is 100% safe to use during pregnancy.</span>
                </span>
              </li>
            </ul>
          </div>

          <div class="five columns offset-by-two">
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Will the Aqua Bed Warmer Mattress Pad break or leak?
                  <span
                    class="hiw__faqs--answer"
                  >The Aqua Bed Warmer mattress pad comprises two components: the internal tube-circulation system and the 100% cotton padding. Both components are built to endure immense weight pressure. Thus, the internal tube-circulation system will not clog or leak unless concentrated, even if sharp pressure from something like a chair is rested upon our mattress pad.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Is the Aqua Bed Warmer safe for my parents/grandparents?
                  <span
                    class="hiw__faqs--answer"
                  >The Aqua Bed Warmer is very safe for parents and grandparents particularly because it eliminates the risk of overheating/burning, which is a common problem associated with electric blankets. Since your parents/grandparents will be able to adjust their heating temperatures/runtimes, you won’t have to worry about them getting too hot or uncomfortable at night.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Is the Aqua Bed Warmer safe for my children?
                  <span
                    class="hiw__faqs--answer"
                  >The Aqua Bed Warmer is a great and safe choice for children. However, we strongly urge all parents to take precautions when setting temperatures and runtimes. Also, we do not recommend that babies or infants sleep alone with the Aqua Bed Warmer.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Where can I purchase my Aqua Bed Warmer?
                  <span class="hiw__faqs--answer">
                    At this time, the Aqua Bed Warmer is exclusively available on AquaBedWarmer.com and Amazon.com. If you'd like to place your order over the phone, please call our toll free line at
                    <a
                      href="tel:+18556462782"
                      rel="noopener"
                    >(855)646-AQUA</a>.
                  </span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  How do I register my Aqua Bed Warmer warranty?
                  <span
                    class="hiw__faqs--answer"
                  >Please visit our warranty registration page to register your Aqua Bed Warmer.</span>
                </span>
              </li>
            </ul>
            <ul>
              <li class="hiw__faqs--question--container">
                <span class="hiw__faqs--question">
                  Can I wash the Aqua Bed Warmer mattress pad?
                  <span
                    class="hiw__faqs--answer"
                  >Yes. In order to wash your Aqua Bed Warmer mattress pad, disconnect the pad from the water heater. Seal the connectors for both the water heater and the pad with the black rubber caps that were included with your system. This is very important to avoid soapy water from entering the tubing system.
                    <br>
                    <br>Once removed from the bed, use a spray and wash solution to target any stains. Then, wash the pad using warm or cold settings (not hot). For drying, use a delicate dry setting with medium heat. If after one cycle, the pad is still slightly damp, air dry rather than running the pad again.
                    <br>
                    <br>While the twin and full size pads will fit in most washing machines, the queen and king size pads are much larger and may only fit in a commercial washing machine. We recommend washing the mattress pad 1-2 times per year.
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>

<template>
  <div>
    <div
      v-if="page.ID"
      class="generic-page-content">
      <!-- Conditional rendering to determine what component gets rendered -->
      <how-it-works v-if="page.custom_fields.frontend_slug === 'how-it-works'"/>
      <default
        v-else
        :page-data="page"/>
    </div>
    <four-oh-four v-else/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Default from '~/components/page-templates/Default.vue'
import HowItWorks from '~/components/page-templates/HowItWorks.vue'
import FourOhFour from '~/components/404.vue'
import head from '~/mixins/headerMetaData'

export default {
  name: 'PageSlug',
  components: {
    Default,
    HowItWorks,
    FourOhFour
  },
  mixins: [head],
  async asyncData ({ $axios, store, params }) {
    const pageSlug = params.slug
    if (!pageSlug) return { page: { ID: false } }
    const pageExists = store.getters['pages/doesPageExist'](pageSlug)
    if (pageExists) {
      const pageId = store.getters['pages/pageIdFromSlug'](pageSlug)

      try {
        const { data } = await $axios.get(`/api/pages/${pageId}`)
        return { page: data }
      } catch (err) {
        console.log(err)
      }
    } else {
      // If no page exists set the ID to false
      return { page: { ID: false } }
    }
  },
  computed: {
    ...mapGetters('pages', {
      pages: 'pages'
    })
  }
}
</script>

<style lang="scss" scoped>
.generic-page-content {
  font-size: 18px;

  li {
    margin-bottom: 10px;
  }
}
</style>
